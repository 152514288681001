import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['quizQuestion1', 'quizQuestion2', 'quizQuestion3', 'quizQuestion4', 'quizQuestion5'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Paper = makeShortcode("Paper");
const Quiz = makeShortcode("Quiz");
const GoTo = makeShortcode("GoTo");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "quiz"
    }}>{`Quiz`}</h1>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion1" once={true} question="QUESTION 01: I should try to avoid all conflict in the family." fallbackResponse={<p>
        The correct answer is: <b>False</b>. Conflict shouldn’t be avoided, but it is important to handle it as well as
        possible. See <GoTo to="/m5-conflict/02-manage-disagreements" mdxType="GoTo">Managing disagreements and differences</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: false
      }, {
        name: 'False',
        correct: true
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion2" once={true} question="QUESTION 02: In order to encourage my teenager to be more of an adult, it is helpful to get them to take sides in any heated arguments between my partner and me." fallbackResponse={<p>
        The correct answer is: <b>False</b>. When you are having a disagreement with your partner, remember to keep your
        teenager out of it. See{' '}
        <GoTo to="/m5-conflict/03-conflict-partner" mdxType="GoTo">Conflict with your partner? Leave your teenager out of it</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: false
      }, {
        name: 'False',
        correct: true
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion3" once={true} question="QUESTION 03: I should use a conflict situation as an opportunity to talk about other problems which have been bothering me." fallbackResponse={<p>
        The correct answer is: <b>False</b>. You should focus on one problem at a time. See{' '}
        <GoTo to="/m5-conflict/06-ground-rules-cont" mdxType="GoTo">Ground rules</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: false
      }, {
        name: 'False',
        correct: true
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion4" once={true} question="QUESTION 04: When I say “I feel really frustrated when you spend so much time on the computer”, I am using assertive communication." fallbackResponse={<p>
        The correct answer is: <b>True</b>. Assertive communication means expressing yourself effectively and standing
        up for your point of view, while also respecting the rights and beliefs of others. See{' '}
        <GoTo to="/m5-conflict/10-communication-styles" mdxType="GoTo">Communication styles</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: true
      }, {
        name: 'False',
        correct: false
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion5" once={true} question="QUESTION 05: I should continue to show affection to my teenager even when we have arguments that have not been resolved." fallbackResponse={<p>
        The correct answer is: <b>True</b>. It is important to keep showing your teenager affection and to have everyday
        conversations with them, even if you are having ongoing disagreements. See{' '}
        <GoTo to="/m5-conflict/12-carry-on-loving-them" mdxType="GoTo">No end to the arguments</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: true
      }, {
        name: 'False',
        correct: false
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <SingleImage gridSize={4} smallGridSize={8} src="/images/shared/51.1-01.svg" alt="thinking woman" mdxType="SingleImage" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      